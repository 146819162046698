:root {

  --primary: #002E58;
  --secondary: #D9E2EB;
  --danger: #B01D1F;
  --dark-grey: #303030;
  --light-grey: #A6ABB1;
  --grey: #8E8E8E;
  --green-label: #41AA01;
  --black-mana: #868686;
  /* brands color */
  --brand-1: #B01D1F;
  --brand-2: #7EB5E4;
  --brand-3: #E9B642;
  --brand-4: #7BAE40;
  --check-box: #AA0101;
  --background-warning: #FFF6E4;
  --white: #FFFFFF;



  --font-secondary: #717171;


  /* Font size */
  --size-xlarge: 3rem;
  --size-large: 2.2rem;
  --size-medium: 2rem;
  --size-regular: 1.6rem;
  --size-small: 1.4rem;
  --size-xsmall: 1.2rem;



}