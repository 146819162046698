/* colors */

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.danger {
  color: var(--danger);
}

.grey {
  color: var(--grey);
}

.brand-1 {
  color: var(--brand-1);
}

.brand-2 {
  color: var(--brand-2);
}

.white {
  color: white;
}

.brand-3 {
  color: var(--brand-3);
}

.brand-4 {
  color: var(--brand-4);
}

.font-secondary {
  color: var(--font-secondary);
}

.green-label {
  color: var(--green-label);
}

.dark-grey {
  color: var(--dark-grey);
}

.light-grey {
  color: var(--light-grey);
}

.black-mana {
  color: var(--black-mana);
}

/* font size */
.font-xl {
  font-size: var(--size-xlarge);
}

.font-lg {
  font-size: var(--size-large);
}

.font-md {
  font-size: var(--size-medium);
}

.font-rg {
  font-size: var(--size-regular);
}

.font-sm {
  font-size: var(--size-small);
}

.font-xs {
  font-size: var(--size-xsmall);
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.no-drop {
  cursor: no-drop;
}

.letter-05 {
  letter-spacing: -0.05em;
}

.letter-03 {
  letter-spacing: -0.03em;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.underline:hover {
  text-decoration: none;
}

.word-break-all {
  word-break: break-all;
}

/* auth */

.h-100vh {
  height: 100vh;
}

.eyeicon {
  position: absolute;
  top: 43px;
  right: 35px;
}

.auth-bg {
  background-image: url(/assets/img/auth/bg.svg);
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 100%;
  background-color: #D9E2EB;
}

.maintitle {
  margin-left: -23px;
}

.first-letter-capitalize::first-letter {
  text-transform: uppercase;
}

.mat-select-min-line::first-letter {
  text-transform: uppercase !important;
}

.first-letter-capitalize {
  text-transform: lowercase;
  display: inline-block;
}

.subtitle {
  margin-left: -23px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #303030;
}


.welcome-btn {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
}

.welcome-btn:hover {
  border-color: #002E58;
}

.choose-btn:hover {
  border-color: #002E58;
}

.auth-text {
  margin-top: 18rem;
  padding-left: 9rem;
}


.motivation-field {
  height: 13rem !important;
  border-radius: 7px;
}

.auth-field {
  border-radius: 7px;
  height: 6rem;
  font-family: 'Poppins';
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  /* color: #868686; */
}

.auth-btn {
  background: #002E58;
  border-radius: 5px;
  color: white;
}

/* .auth-btn:hover {
  background-color: white;
  color: #002E58;
  border: 1px solid #002E58;
} */

.auth-btn:disabled {
  background: #6F869C;
}

.formsignup {
  width: 700px;

}


.h-75vh {
  height: 75vh;
}

.reg-sidebar {
  position: fixed;
  background: white;
  height: 100%;
  width: 28rem;
  margin-left: 0;
}

/* sidebar */
.help_center {
  background-image: url(/assets/img/sidebar/help_center.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

/* student dashboard */
.dashboard {
  /* background-color: #002E58; */
  /* opacity: 80%; */
  border-radius: 13px;
}

.student-approved {
  background: #41AA01;
  border-radius: 40px;
}

.student-rejected {
  background: #B01D1F;
  border-radius: 40px;
}

.student-submitted {
  background: #E9B642;
  border-radius: 40px;

}

.grp-details-card {
  background-color: white;
  border-radius: 10px;
  transition: 0.3s;
}

.due-dates-card {
  background-color: white;
  border-radius: 10px;
  color: black;
}

.recieve-bank-card {
  border: 3px solid #F8F8F8;
  border-radius: 13px;
}

.donation-card {
  background: url(/assets/img/student_dashboard/donationimg.png);
  background-size: cover;
  border-radius: 13px;
  background-repeat: no-repeat;
}

.collectsponser-card {
  background-image: url(/assets/img/admin_dashboard/sponsor-bg-2.svg);
  background-size: cover;
  border-radius: 13px;
  background-repeat: no-repeat;
}

.donationsponser-card {
  background: url(/assets/img/other/mysponser2.png);
  background-size: cover;
  border-radius: 13px;
  background-repeat: no-repeat;
}

.donation {
  margin-top: -7px;
}

.sharebtn {
  background-color: #866C38;
  border-radius: 5px;
  color: white;
  border: 1px solid #866C38;
}

.addsponserbtn {
  background-color: transparent;
  border-radius: 5px;
  color: #866C38;
  border: 1px solid #866C38;

}

.grp-details-card:hover {
  transform: translate(0, -9px);
}

.black {
  color: #000000;
}


/* profilebar */
.profile-name {
  background: #EEFAF3;
  border-radius: 2px;
}

.profile-btn {
  border: 1px solid #F1F1F1;

  border-radius: 5px;
}



.profileBtn-selected {
  border: 1px solid #002E58;
}

/* general */


.gender {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
}

.shirt-size {
  border: 1px solid #E4E4E4;
  /* border-radius: 20px; */
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: #bbb; */
  border-radius: 50%;
  /* display: inline-block; */
}

/* motivation */
.motivation-file {
  background: #E0F0FF;
  border: 1px dashed #7EB5E4;
  border-radius: 5px;
}

.choose-btn {
  background: #002E58;
  border-radius: 5px;
}

/* privacy dialog  */

.privacy-dialog {
  border-radius: 15px !important;
}

/* edit profilebar */
.change-img-btn {
  border: 1px solid #002E58;
  border-radius: 5px;
}

.edit-name {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
}

.cancel-btn {
  border: 1px solid #002E58;
  border-radius: 5px;
}

/* .cancel-btn:hover {

  background-color: #002E58;
  color: white;
} */

.update-btn {
  background: #002E58;
  border-radius: 5px;
}

.grey-light {
  color: #aaaaaa;
}

/* .update-btn:hover {
  border: 1px solid #002E58;
  background-color:#ffffff;
  color: #002E58;
} */

.groups-card {
  background-color: var(--danger);
  border-radius: 1rem;
  /* height: 17.4rem; */
  box-shadow: 0px 22px 10px -15px rgba(176, 29, 31, 0.2);
}

.students-card {
  background-color: var(--brand-2);
  border-radius: 1rem;
  /* height: 17.4rem; */
  box-shadow: 0px 22px 10px -15px rgba(126, 181, 228, 0.2);
}

/* my groups */

.filter-btn {
  border: 1px solid #002E58;
  border-radius: 5px;
  color: var(--primary);
}

.filter-btn-selected {
  border: 1px solid #002E58;
  background-color: var(--primary);
  border-radius: 5px;
  color: white;
}

.student-img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}

.group-letter {
  width: 4.5rem;
  padding: 17px;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}

.country-detail {
  background: #F8F8F8;
  border-radius: 10px;
}

.closed-status {
  background: #FFFEDE;
  border-radius: 2px;
  color: #CDC500;
}

.cancelled-status {
  background: #FFF0F0;
  border-radius: 2px;
  color: #D10000;
}

.completed-status {
  background: #EEFAF3;
  border-radius: 2px;
  color: #41AA01;
}

.open-status {
  background: #E8F9FF;
  border-radius: 2px;
  color: #00A6DB;
}

.progress-status {
  background: #FFFEDE;
  border-radius: 2px;
  color: #CDC500;
}

.student-table-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.approve-btn {
  background: var(--primary);
  border-radius: 5px;
  color: white;
}

.reject-btn {
  border: 1px solid #002E58;
  border-radius: 5px;
  color: var(--primary);
  background-color: white;
}

.empty-dashboard {
  background-image: url(/assets/img/teacher_dashboard/empty_dashboard.svg);
  background-repeat: no-repeat;
  background-color: var(--primary);
  border-radius: 10px;
  background-position-x: 60rem;
}

/* onboarding */
.yesBtn {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
}

.yesBtn-selected {
  background: rgba(0, 46, 88, 0.1);
  border: 1px solid #002E58;
  border-radius: 5px;
}

.general-intro {
  background-size: cover;
  background-image: url(/assets/img/onboarding/general/banner.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: right;

}

.general_top {
  top: -195px;
  position: absolute;
  right: 0rem;
  height: 193px;
}

.motivation_img_top {
  top: -171px;
  position: absolute;
  right: -14px;
}

.general_img {
  height: 194px;
}

.motivation_img {
  height: 200px;
}

.size-selected {
  background: rgba(0, 46, 88, 0.1);
  border: 1px solid #002E58;
  border-radius: 20px;
}

.lineHeight-22 {
  line-height: 2.2rem;
}

.motivation-intro {
  background-size: cover;
  background-image: url(/assets/img/onboarding/motivation/banner.png);
  background-repeat: no-repeat;
  border-radius: 3px;

}

.destination-btn {
  background: #F8F8F8;
  border-radius: 5px;
}

.search-filter {
  background: #ffffff;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  height: 46px;
  display: flex;
}

.nationality-dropdown {
  height: 35px;
  font-size: 14px !important;
}

.status-text {
  color: #707070
}

.disabled-field {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  align-items: center;
  display: flex;
  color: var(--grey);
  cursor: no-drop;
}

.spinner-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 998;

}

.logout {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: #000000;
}

.subout {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #717171;
}

.sidebar {
  position: fixed;
  background: white;
  height: 100%;
  width: 28rem;
  margin-left: 0;
}


@media screen and (max-height: 480px) {
  .sidebar {
    overflow: auto;
  }

  .profile-bar {
    overflow: auto;
  }

}

::ng-deep .rating.color-ok .star-container .star svg {
  fill: #E9B642 !important;
}

.starScale {
  margin: auto;
  font-size: var(--size-xsmall);
  font-weight: 600;
  color: var(--dark-grey);
}


/* Flight Popup */
.textcolor {
  color: #717171;
}

.bordercolor {
  border: 1px solid #F9F9F9;
}

.dashedborder {
  border: 1px dashed #DDDDDD;
}

.flight-card {
  background-image: url(/assets/img/student_dashboard/ticket.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.placename {
  color: #000000;
  font-size: 22px;
}

.departbtn {
  border-radius: 35px;
  height: 45px;
  background: #ffffff !important;
  border: 1px solid #002E58 !important;
  color: #002E58;
}

.arrivalbtn {
  border-radius: 35px;
  height: 45px;
  background: #ffffff !important;
  border: 1px solid #002E58 !important;
  color: #002E58;
}




/* Teacher and Medical Popup */

.assigned-card {
  width: 70px;
  background: #EEFAF3;
  border-radius: 2px;
  color: #41AA01;
}

.assigned-medical-card {
  /* width: 90px; */
  background: #EEFAF3;
  border-radius: 2px;
  color: #41AA01;
}

.round-btn {
  border: 1px solid #002E58;
  border-radius: 35px;
  color: var(--primary);
}

/* .round-btn:hover {
  background-color: var(--primary);
  color: white;
} */

.selected-round-switch {
  background-color: var(--primary);
  color: white;
}

.sticky-position {
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  z-index: 1;
}

.table-responsive::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px #F9F9F9; */
  border-radius: 10px;
  background-color: #F9F9F9;
}

.table-responsive::-webkit-scrollbar {
  height: 6px;
  width: 5px;
  background-color: #F9F9F9;
}

.z-index-1000 {
  z-index: 1000;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px #F9F9F9; */
  background-color: #D0D0D0;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.gap-12 {
  gap: 12px;
}

.profilebar {
  width: 335px !important;
  border-left: none !important;
}

.regBar {
  width: 28rem;
}

.white-bg {
  background: white;
}

.onboarding-btn {
  position: fixed;
  bottom: 0px;
  left: 0;
  background-color: white;
}

.dashboard-btn {
  position: fixed;
  height: 102px;
  bottom: 0;
  right: 335px;
  background-color: white;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.email-cell {
  padding-right: 3rem !important;
  width: 35rem;
  word-break: break-all;
}

.margin-btm-15 {
  margin-bottom: 15%;
}

/* media query */
@media (max-width: 1347px) {
  .margin-btm-15 {
    margin-bottom: 30%;
  }

  .reg-sidebar {
    width: 26rem !important;
  }

  .lang-btn {
    width: 24rem !important;
  }

  .regBar {
    width: 26rem;
  }

  .sidebar {
    width: 26rem;
  }
}

.no-group {
  background-image: url(/assets/img/teacher_dashboard/no-grp.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 22rem;
}

.mat-option-class {
  margin-top: 20px;
  margin-left: 8px;
}

.min-wdith-table {
  min-width: 80rem;
}

/* responsive */
@media (max-width: 1200px) {
  .onboarding-tick {
    width: 30px !important;
    height: 30px !important;
  }

  .onboarding-line {
    left: 12px !important;
  }

  .onboarding-icon {
    font-size: 20px !important;
  }
}

.btn-margin {
  margin-left: 28rem;
}


mat-toolbar {
  background-color: white !important;
}

@media (max-width: 576px) {
  .font-sm {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 768px) {
  .margin-btm-15 {
    margin-bottom: 40%;
  }
}

@media (max-width: 992px) {

  .btn-margin {
    margin-left: 0rem;
  }

  mat-dialog-content {
    max-height: 28vh !important;
  }

  .content {
    padding-top: 10% !important;
  }

  .main-content {
    padding-top: 64px;
  }

  .regBar {
    width: 100%;
  }

  .lang-btn {
    width: 25rem !important;
  }

  .profilebar {
    width: 100% !important;
  }

  .sidebar {
    width: 100%;
  }

  .dashboard-btn {
    right: 0px;
  }
}