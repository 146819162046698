/* You can add global styles to this file, and also import other style files */
@import '~css-star-rating/css/star-rating.min.css';
@import 'node_modules/ngx-toastr/toastr.css';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

mat-sidenav-content {
  height: 100% !important;
}

mat-sidenav-content::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: #F9F9F9 !important;
}

mat-sidenav-content::-webkit-scrollbar {
  width: 5px !important;
  background-color: #F9F9F9 !important;
}

mat-sidenav-content::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #D0D0D0 !important;
}

html,
body {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;

}

.mat-radio-button {
  font-family: 'Poppins', sans-serif !important;
}

.mat-raised-button {
  font-family: 'Poppins', sans-serif !important;
}

.mat-button {
  font-family: 'Poppins', sans-serif !important;
}

.mat-tooltip {
  font-family: 'Poppins', sans-serif !important;
}

.mat-datepicker-content {
  font-family: 'Poppins', sans-serif !important;
}

.mat-select-placeholder {
  font-family: 'Poppins', sans-serif !important;
}

.popover-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F9F9F9;
}

.popover-body::-webkit-scrollbar {
  width: 5px;
  background-color: #F9F9F9;
}

.popover-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D0D0D0;
}

.popover-body {
  z-index: 1000;
  max-height: 20rem !important;
  overflow-y: scroll !important;
  font-family: 'Poppins', sans-serif !important;
}

.mat-select-value-text {
  font-family: 'Poppins', sans-serif !important;
}

.mat-menu-item {
  font-family: 'Poppins', sans-serif !important;
}

.mat-expansion-panel-header {
  font-family: 'Poppins', sans-serif !important;
}

.popover {
  max-width: 350px !important;
  border: none !important;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 35px 5px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
}

html {
  font-size: 62.5% !important;
}

mat-tab-group {
  font-family: "poppins" !important;
}

mat-label {
  font-family: "poppins" !important;
}

.mat-table {
  font-family: "poppins" !important;
}

input {
  font-family: "poppins" !important;
}


mat-dialog-container {
  border-radius: 15px !important;
}

mat-option {
  font-family: "poppins" !important;
}

.mat-tab-label {
  font-family: "poppins" !important;
  font-weight: 600 !important;
  padding: 0 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

input:active {
  border-color: #002E58 !important;
}


input:focus {
  border-color: #002E58 !important;
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  box-shadow: none !important;
  border-color: #002E58 !important;
  ;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:disabled {
  cursor: no-drop;
}

textarea {
  resize: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

tr.mat-header-row {
  background: #F8F8F8 !important;
  border-radius: 3px !important;
  height: 3.8rem !important;
}

tr.mat-row {
  height: 5.6rem !important;
  border-bottom: 2px solid #F9F9F9 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: black !important;
  border-radius: 2px !important;
}

.form-control:disabled {
  background-color: white !important;
}

th.mat-header-cell {
  border-bottom: none !important;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

@media (max-width: 599px) {
  .mat-tab-label {
    min-width: 160px !important;
  }
}

.word-wrap-break {
  word-wrap: break-word;
}

.word-break {
  word-break: break-word;
}


.mat-snack-bar-container {
  border-radius: 5px !important;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw !important;
  min-width: 355px !important;
  padding: 0px 0px !important;
  min-height: 0px !important;
  transform-origin: center;
  box-shadow: none !important;
  /* background: none !important; */
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary) !important;
}

.mat-radio-ripple {
  display: none !important;
}

mat-sidenav-container {
  background-color: white !important;
}

mat-sidenav {
  background: white !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: white;
}

mat-checkbox {
  font-family: "poppins" !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary) !important;
}

.mat-raised-button.mat-primary {
  background: var(--primary) !important;
}

.mat-calendar-body-selected {
  background: var(--primary) !important;
}

.form-control {
  padding: 0.375rem 4.6rem 0.375rem 1.6rem !important;
  height: 5rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #a9a9a9 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-color: #a9a9a9 !important;
  border-width: 1px !important;
}

mat-select:focus {
  box-shadow: none !important;
}

.mat-progress-bar-fill:after {
  background-color: #00733B !important;
}

.mat-row:hover {
  background-color: #F9FCFF !important;
}

.mat-select-panel::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F9F9F9;
}

.mat-select-panel::-webkit-scrollbar {
  width: 5px;
  background-color: #F9F9F9;
}

.mat-select-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D0D0D0;
}

.cdk-global-scrollblock {
  position: static;
  width: initial;
  overflow: hidden !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0.25em !important;
}

#customPaginator .mat-form-field-infix {
  border-top: 0 !important;
}

.mat-datepicker-actions {
  justify-content: start !important;
}

.mat-option.hide {
  display: none !important;
}

mat-sidenav {
  height: 100% !important;
}

.my-dialog .mat-dialog-container {
  padding: 0px !important;
}



.mat-expansion-panel {
  box-shadow: none !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 5px !important;
}

.border-bottom {
  border-bottom: 1px solid #F9F9F9 !important;
}

.mat-select-arrow-wrapper {
  height: 0px !important;
}

.tableimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

th {
  color: var(--grey) !important;
  font-size: 12px !important;
}

.mat-tab-list .mat-tab-labels .mat-tab-label-active {
  color: black !important;
  opacity: 100% !important;
}

.mat-tab-label {
  color: #A0A0A0 !important;
}

/* .mat-select-panel{
  max-height: 174px !important;
} */

.mat-chip.mat-standard-chip {
  background: #F1F1F1 !important;
  border-radius: 4px !important;
}

.matOption::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F9F9F9;
}

.matOption::-webkit-scrollbar {
  width: 5px;
  background-color: #F9F9F9;
}

.matOption::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D0D0D0;
}

#contract .mat-checkbox-layout {
  white-space: inherit !important;
}

#contract .mat-checkbox-inner-container {
  margin: 4px 8px auto 15px !important;
}

.mat-icon {
  top: 2px !important;
  position: relative !important;
  left: 2px !important;
}

/* .mat-option.mat-active{
  background: none !important;
} */
.myPanelClass {
  margin-top: 30px !important;
}

.dropdownPanel {
  margin-top: 38px !important;
}

.margin-btn-15 {
  margin-bottom: 15%;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #000000 !important;
  opacity: 40%;
}

.mat-input-element:disabled {
  color: black !important;
  font-weight: 600 !important;
}

#filter-dropdown .mat-form-field-flex {
  padding: 0 0.75em 0.3em 0.75em !important;
}

.mat-select-placeholder {
  color: var(--grey) !important;
  font-weight: 500 !important;
}

/* material date  */
#material-date .mat-form-field-infix {
  display: flex !important;
}

#material-date {
  display: flex !important;
}

#material-date .mat-form-field-wrapper {
  width: 100% !important;
}

#material-date .mat-form-field-infix {

  padding: 0.2em 0 !important;
}

#material-date .mat-input-element {
  font-size: 14px !important;
}

#mat-dropdown {
  width: 7rem !important;
}

#mat-dropdown .mat-form-field-infix {
  border-top: 0.4em solid transparent !important;
  padding: 0.5em 0 !important;
}

#mat-dropdown .mat-form-field-outline {
  background-color: white !important;
  border-radius: 6px !important;
}

#material-date .mat-form-field-appearance-outline .mat-form-field-outline {
  color: none !important;
  border: 1px solid #a9a9a9 !important;
  border-radius: 5px !important;
}

#custom-border .mat-form-field-outline {
  color: #a9a9a9 !important;
}

#custom-border .mat-form-field-flex {
  padding: 0 0.75em 0.3em 0.75em !important;
}

@-moz-document url-prefix() {
  #custom-border .mat-form-field-flex {
    padding: 0 0.75em 0em 0.75em !important;
  }
}

.mat-expansion-panel-header-title {
  margin-right: 5px !important;
}

.toast-container .ngx-toastr {
  color: black !important;
  box-shadow: none !important;
  padding: 15px 15px 15px 15px !important;
  width: 230px !important;
}

.toast-bottom-right {
  bottom: 90px !important;
}

.toast-success {
  background-image: none !important;
}

.mat-progress-bar-buffer {
  background-color: #FFFFFF !important;
}

.mat-progress-bar {
  border-radius: 10px !important;
  height: 5px !important;
}

.mat-paginator {
  display: flex !important;
  justify-content: start !important;
}


/* ng-neat - popover  */
.tippy-box {
  max-width: 500px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
  word-break: break-word;
}


.tippy-box::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: #F9F9F9 !important;
}

.tippy-box::-webkit-scrollbar {
  height: 6px !important;
  width: 5px !important;
  background-color: #F9F9F9 !important;
}

.tippy-box::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #D0D0D0 !important;
}

/* //=======> CHECK SKELTON LOADING FIELDS */
.skelton {
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
  border-left: none !important;
  background-color: none !important;
  background: none !important;
  pointer-events: none !important;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,
        hsl(210, 15%, 90%),
        hsl(210, 15%, 95%),
        hsl(210, 15%, 90%));
    border-radius: 5px;
    background-size: 200%;
    animation: loader 1s infinite reverse;
    z-index: 2;
  }

  @keyframes loader {
    from {
      background-position: -100% 0;
    }

    to {
      background-position: 100% 0;
    }
  }
}

.scrollable-container {
  height: calc(100vh - 110px);
  overflow-y: auto;
}